<template>
  <div class="h-full">
    <h2 class="text-blueGray-400 uppercase font-semibold text-xs">
      {{ $t(title) }}
    </h2>
    <div class="flex justify-center items-center h-full flex-grow">
      <i v-if="loading" class="fas fa-sync fa-spin" />
      <div v-else-if="chartData && chartData.length > 0">
        <apexchart
          :key="`${chartData.length}-filteredAssets-${chartKey}`"
          type="donut"
          height="350"
          :options="chart.chartOptions"
          :series="chart.series"
        />
        <div v-if="assets.length > 0">
          <el-select
            @change="updateChart"
            v-model="selectedAsset"
            collapse-tags
            multiple
            placeholder="Select"
          >
            <el-option
              v-for="item in assets"
              :key="item.qr_id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <h2 v-else class="font-semibold text-lg">
        {{ $t("no data to plot") }} 🥺
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericPieChart",
  props: {
    title: {
      type: String,
      defualt: false
    },
    loading: {
      type: Boolean,
      defualt: false
    },
    chartData: {
      type: Array,
      require: true
    }
  },
  computed: {
    chart() {
      let chartData = this.chartData.filter(item => {
        if (this.selectedAsset.length == 0) {
          return true;
        }
        return (
          item.asset != null && this.selectedAsset.indexOf(item.asset.id) !== -1
        );
      });
      const { series, /*colors,*/ labels } = this.getPieChartData(chartData);
      return {
        series,
        chartOptions: {
          // fill: { colors },
          // colors,
          chart: {
            type: "donut",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: "thin"
          },
          labels,
          legend: {
            position: "bottom",
            offsetY: 0
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#333"]
            },
            background: {
              enabled: true,
              foreColor: "#fff",
              borderWidth: 0
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                }
              }
            }
          ]
        }
      };
    }
  },
  data() {
    return {
      selectedAsset: [],
      assetFilters: [],
      assets: [],
      chartKey: 0
    };
  },
  methods: {
    updateChart() {
      this.chartKey = this.chartKey + 1;
    },
    getPieChartData(items) {
      const statusDistribution = items.reduce((accu, item) => {
        const statusName =
          item.single_value == null ? "n/a" : item.single_value;

        if (statusName in accu) {
          accu[statusName].tally = accu[statusName].tally + 1;
        } else {
          // const color = item?.status?.color ? item?.status?.color : "#e3e3e3";
          accu[statusName] = {
            tally: 1
            // color
          };
        }
        return accu;
      }, {});

      return {
        labels: Object.keys(statusDistribution),
        series: Object.values(statusDistribution).map(({ tally }) => tally)
        // colors: Object.values(statusDistribution).map(({ color }) => color)
      };
    }
  },
  created() {
    const seen = {};
    this.assets = this.chartData
      .filter(item => {
        if (item.asset != null && !(item.asset.id in seen)) {
          seen[item.asset.id] = true;
          return true;
        }
        return false;
      })
      .map(item => item.asset);
  }
};
</script>
