<template>
  <p class="bg-blueGray-100 rounded h-full py-2 px-3">
    <span v-if="item.single_value">
      <router-link
        class="text-blue-700 underline"
        v-if="isVaultQR"
        :to="{ name: 'QREntry', params: { qr_id: item.single_value } }"
      >
        {{ item.single_value }}
      </router-link>
      <span v-else>
        {{ item.single_value }}
      </span>
    </span>
    <span v-else>
      {{ $t("N/A") }}
    </span>
  </p>
</template>

<script>
export default {
  name: "ResultAnswers",
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  computed: {
    isVaultQR() {
      return /^[a-z]{1}_[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
        this.item.single_value
      );
    }
  }
};
</script>
