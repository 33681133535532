<template>
  <div class="bg-blueGray-100 rounded h-full py-2 px-3">
    <i
      v-if="item.custom_data.type == 'check' && item.pass"
      class="fas fa-check-circle text-teal-700"
    />
  </div>
</template>

<script>
export default {
  name: "ResultCheckoffs",
  props: {
    item: {
      type: Object,
      require: true
    }
  }
};
</script>
