<template>
  <div class="mb-3">
    <h2
      class="font-semibold text-md text-coolGray-500 border-l-8 px-5 border-green-500 uppercase"
    >
      {{ custom_data.question }}
    </h2>
    <h3 class="font-semibold text-sm -mt-1 text-coolGray-400 ml-8">
      {{ custom_data.description }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "ResultHeading",
  props: {
    custom_data: {
      type: Object,
      require: true
    }
  }
};
</script>
