var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-screen h-screen md:flex fixed top-0 left-0"},[_c('i',{staticClass:"fas fa-times fixed right-4 top-3 text-xl cursor-pointer",on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"w-full md:w-2/5 flex items-center px-5 bg-blueGray-100"},[_c('div',[(_vm.checkout && _vm.checkout.qr_id)?_c('div',[_c('QrCode',{key:_vm.checkout.qr_id,attrs:{"item":_vm.checkout,"nameKey":"subject","borderLess":true}})],1):_vm._e(),_c('h1',{staticClass:"text-3xl px-8 py-4 font-bold text-blueGray-500 bg-white md:bg-blueGray-100 rounded-t-lg"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.bike_id)?_c('h2',{staticClass:"text-xl px-8 -mt-5 font-bold text-blueGray-400 bg-white md:bg-blueGray-100"},[(_vm.bikesByID[_vm.bike_id])?_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("asset"))+": ")]):_vm._e(),(_vm.bikesByID[_vm.bike_id] && _vm.bikesByID[_vm.bike_id].name)?_c('span',[_vm._v(" "+_vm._s(_vm.bikesByID[_vm.bike_id].name)+" ")]):_vm._e(),_vm._v(" / "),(_vm.bikesByID[_vm.bike_id].hub)?_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("hub"))+": ")]):_vm._e(),(
            _vm.bikesByID[_vm.bike_id] &&
            _vm.bikesByID[_vm.bike_id].hub &&
            _vm.bikesByID[_vm.bike_id].hub.name
          )?_c('span',[_vm._v(" "+_vm._s(_vm.bikesByID[_vm.bike_id].hub.name)+" ")]):_vm._e(),(_vm.checkListDuration)?_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("duration"))+": ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.checkListDuration)+" ")])]):_vm._e()])]),_c('div',{staticClass:"h-screen w-full md:w-3/5 overflow-scroll py-20 px-10 bg-white"},_vm._l((_vm.items),function(item,index){return _c('div',{key:((item.id) + "-" + index),staticClass:"py-1 text-coolGray-700",class:{
        'px-5': item.custom_data.type != 'heading',
        '-mb-2 py-4': item.custom_data.type == 'heading',
        'border-t mt-6': item.custom_data.type == 'heading' && index != 0
      }},[(item.custom_data.type == 'heading')?_c('ResultHeading',{attrs:{"custom_data":item.custom_data}}):_vm._e(),(item.custom_data.type != 'heading')?_c('ResultLayout',{attrs:{"item":item}},[_c('div',[(item.custom_data.type == 'check')?_c('ResultCheckoffs',{attrs:{"item":item}}):_vm._e(),(item.custom_data.type == 'answer')?_c('ResultAnswers',{attrs:{"item":item}}):_vm._e(),(
              item.custom_data.type == 'temp' ||
              item.custom_data.type == 'number'
            )?_c('ResultNumbers',{attrs:{"item":item}}):_vm._e(),(item.custom_data.type == 'dateTime')?_c('ResultDateTime',{attrs:{"item":item}}):_vm._e(),(
              item.custom_data.type == 'checkbox' ||
              item.custom_data.type == 'option' ||
              item.custom_data.type == 'radio' ||
              item.custom_data.type == 'yesno'
            )?_c('ResultOptions',{attrs:{"item":item}}):_vm._e(),(item.medias && item.medias.length > 0)?_c('ResultMedias',{attrs:{"medias":item.medias}}):_vm._e()],1)]):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }