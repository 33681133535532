<template>
  <div class="grid grid-cols-3 gap-4 mt-3">
    <div
      class="relative"
      v-for="(media, index) in medias"
      :key="`${index}-${media.id}`"
    >
      <img
        v-if="isImage(media)"
        class="rounded-lg object-cover w-full h-28"
        :src="media.path"
      />
      <video
        height="100%"
        class="rounded-lg object-cover w-full h-28"
        v-if="isVideo(media)"
        controls
      >
        <source :src="media.path" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultMedias",
  props: {
    medias: {
      type: Array,
      require: true
    }
  },
  methods: {
    isType({ content_type }, mediaType) {
      if (content_type) {
        return content_type.includes(mediaType);
      } else {
        return false;
      }
    },
    isImage(media) {
      return this.isType(media, "image");
    },
    isVideo(media) {
      return this.isType(media, "video");
    }
  }
};
</script>
