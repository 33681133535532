<template>
  <div class="h-full">
    <h2
      class="text-blueGray-400 uppercase font-semibold text-xs flex justify-between"
    >
      <span>
        {{ $t(title) }}
        <span v-if="customData && customData.unit">
          ({{ customData.unit }})
        </span>
      </span>
      <span>
        <span v-if="customData && customData.min">
          {{ $t("min") }}: {{ customData.min }}
        </span>
        <span v-if="customData && customData.max">
          {{ $t("max") }}: {{ customData.max }}
        </span>
      </span>
    </h2>
    <!-- <div class="flex justify-center items-center h-full flex-grow"> -->
    <div>
      <i v-if="loading" class="fas fa-sync fa-spin" />
      <div v-else-if="chartData && chartData.length > 0">
        <apexchart
          :key="`${chartData.length}-line`"
          type="line"
          height="350"
          :options="chart.chartOptions"
          :series="chart.series"
        />
      </div>
      <h2 v-else class="font-semibold text-lg">
        {{ $t("no data to plot") }} 🥺
      </h2>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "GenericLineChart",
  props: {
    title: {
      type: String,
      defualt: false
    },
    loading: {
      type: Boolean,
      defualt: false
    },
    chartData: {
      type: Array,
      require: true
    },
    customData: {
      type: Object,
      require: false
    },
    max: {
      type: Number,
      require: false
    },
    min: {
      type: Number,
      require: false
    }
  },
  computed: {
    chart() {
      let annotations = { yaxis: [] };
      if (this.customData?.min) {
        annotations.yaxis.push({
          y: this.customData?.min,
          borderColor: "#00E396",
          stroke: 10,
          label: {
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396"
            },
            text: this.$t("min")
          }
        });
      }
      if (this.customData?.max) {
        annotations.yaxis.push({
          y: this.customData?.max,
          borderColor: "#FEB019",
          label: {
            borderColor: "#FEB019",
            style: {
              color: "#fff",
              background: "#FEB019"
            },
            text: this.$t("max")
          }
        });
      }
      const { series, categories } = this.getPieChartData(this.chartData);
      return {
        series,

        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: "thin",
            toolbar: {
              show: false
            }
            // zoom: {
            //   enabled: false
            // }
          },
          dataLabels: {
            enabled: false
          },
          // stroke: {
          //   curve: "straight"
          // },
          stroke: {
            curve: "smooth"
            // width: 3
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories
          },
          annotations,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                }
              }
            }
          ]
        }
      };
    }
  },
  data() {
    return {
      assetFilters: []
    };
  },
  methods: {
    getPieChartData(items) {
      items = items
        .filter(
          item =>
            item.single_number_value != null ||
            item.single_number_value != undefined
        )
        .sort((a, b) => {
          return a.inserted_at.localeCompare(b.inserted_at);
        });

      return {
        categories: items.map(item =>
          moment(item.inserted_at).format("MM/DD hh:mm")
        ),
        series: [
          {
            data: items.map(item => item.single_number_value)
          }
        ]
      };
    }
  }
};
</script>
