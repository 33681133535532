<template>
  <div class="fleet">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="flex px-4 md:px-14 mx-auto w-full z-30 sticky top-24">
      <!-- <div class="bg-green-500 z-50 relative"> -->
      <div
        class="bg-white w-full px-5 flex rounded -mt-6 py-3 shadow-xl cursor-pointer font-semibold text-gray-500 justify-between"
      >
        <div class="flex items-center">
          <div
            class="mx-2"
            v-for="t in tabs"
            :key="t"
            @click="tab = t"
            :class="{
              'border-b-4 border-gray-500': tab == t
            }"
          >
            {{ $t(t) }}
          </div>
        </div>
        <div>
          <el-select
            v-model="selectedHubs"
            multiple
            collapse-tags
            placeholder="Select"
          >
            <el-option
              v-for="hub in $store.state.hubs.hubs"
              :key="hub.id"
              :label="hub.name"
              :value="hub.id"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="px-4 md:px-10 mx-auto w-full">
      <div class="flex flex-wrap mt-4">
        <SlideUp v-if="tab == 'snapshots'">
          <ListTable
            :tableName="`${$t('Checklist Results')} - ${checkListName}`"
            :headers="headers"
            :tableData="checklistResults"
            :loading="$store.state.checkListResults.loading"
            createName=""
          />
        </SlideUp>
        <SlideUp v-if="tab == 'charts'">
          <div
            class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 w-full z-10 px-4 pb-10"
          >
            <div
              class="bg-white p-5 rounded"
              v-for="item in checklistChartables"
              :key="item.id"
            >
              <generic-pie-chart
                v-if="showPie(item)"
                :title="item.name"
                :chartData="results[item.id]"
              />
              <generic-line-chart
                v-if="showLine(item)"
                :title="`${item.name}`"
                :customData="item.custom_data"
                :chartData="results[item.id]"
              />
            </div>
          </div>
        </SlideUp>
      </div>
    </div>

    <el-dialog
      :visible.sync="showPreview"
      :fullscreen="true"
      :show-close="false"
    >
      <ViewCheckListResult
        @close="showPreview = false"
        :items="currentItem"
        :title="checkListName"
        :checkListDuration="checkListDuration"
        :bike_id="bike_id"
      />
    </el-dialog>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";
import ViewCheckListResult from "../components/checklist/ViewCheckListResult.vue";
import GenericPieChart from "../components/charts/GenericPieChart.vue";
import GenericLineChart from "../components/charts/GenericLineChart.vue";
import formatting from "../mixins/formatting";

export default {
  name: "CheckListResults",
  components: {
    ListTable,
    ViewCheckListResult,
    SlideUp,
    GenericPieChart,
    GenericLineChart
  },
  mixins: [formatting],
  computed: {
    checklistChartables() {
      return this.$store.state.checkListItems.checkListItems.filter(
        item => this.showPie(item) || this.showLine(item)
      );
    },
    checklistLineItems() {
      return this.$store.state.checkListItems.checkListItems.filter(item =>
        this.showLine(item)
      );
    },
    // responses() {
    //   return this.$store.state.checkListResults.checkListResults?.[0]
    //     ?.check_list_response;
    // },
    checklistResults() {
      let res = this.$store.state.checkListResults.checkListResults;
      if (this.selectedHubs.length != 0) {
        res = res.filter(r => this.selectedHubs.includes(r?.hub?.id));
      }
      return res;
    },
    results() {
      return this.checklistResults.reduce((accu, item) => {
        item.check_list_response.forEach(resp => {
          if (!(resp.checklist_item_id in accu)) {
            accu[resp.checklist_item_id] = [];
          }
          accu[resp.checklist_item_id].push({
            ...resp,
            inserted_at: item.inserted_at,
            asset: item.bike
          });
        });
        return accu;
      }, {});
      // return this.$store.state.checkListResults.checkListResults?.[0]
      //   ?.check_list_response;
    },
    checkList() {
      return this.$store.state.checkLists.checkList;
    },
    checkListName() {
      return this.$store.state.checkLists.checkList?.name;
    }
  },
  watch: {
    checklistResults() {
      if (this.$route.query.result_id) {
        let item = this.checklistResults.find(
          r => r.id == this.$route.query.result_id
        );
        this.modalClick(item);
      }
    }
  },
  data() {
    return {
      checkListDuration: null,
      selectedHubs: [],
      tabs: ["snapshots", "charts"],
      tab: "snapshots",
      currentItem: null,
      showPreview: false,
      bike_id: null,
      headers: [
        {
          name: "#",
          key: "id",
          type: "modal",
          modalClick: this.modalClick
          // modalClick: item => {
          //   this.bike_id = item?.bike_id;
          //   this.showPreview = true;
          //   this.currentItem = item.check_list_response;
          // }
        },
        {
          name: "user",
          key: "user.name",
          type: ""
        },
        {
          name: "asset",
          key: "bike.name",
          type: ""
        },
        {
          name: "submission time",
          key: "inserted_at",
          type: "dateTime"
        },
        {
          name: "duration",
          key: "custom_data.duration",
          type: "ms"
        }
      ]
    };
  },
  methods: {
    modalClick(item) {
      this.bike_id = item?.bike_id;
      this.showPreview = true;
      this.currentItem = item.check_list_response;

      this.checkListDuration = this.convertMsToMinutesSeconds(
        item?.custom_data?.duration
      );
    },
    showLine(item) {
      let show = false;
      if (item?.custom_data?.type == "number") {
        show = true;
      }
      if (item?.custom_data?.type == "temp") {
        show = true;
      }
      return show;
    },
    showPie(item) {
      let show = true;
      if (item?.custom_data?.type == "heading") {
        show = false;
      }
      if (item?.custom_data?.type == "checkbox") {
        show = false;
      }
      if (item?.custom_data?.type == "check") {
        show = false;
      }
      if (item?.custom_data?.type == "number") {
        show = false;
      }
      if (item?.custom_data?.type == "signature") {
        show = false;
      }
      if (item?.custom_data?.type == "temp") {
        show = false;
      }
      if (item?.custom_data?.type == "answer") {
        show = false;
      }
      if (item?.custom_data?.type == "dateTime") {
        show = false;
      }
      return show;
    }
  },
  created() {
    this.$store.dispatch(
      "checkListResults/getAllCheckListResults",
      this.$route.params.id
    );
    this.$store.dispatch("checkLists/getCheckList", {
      id: this.$route.params.id
    });
    this.$store.dispatch("bikes/getAllBikes", { noFilter: true });

    this.$store.dispatch(
      "checkListItems/getAllCheckListItems",
      this.$route.params.id
    );
  }
};
</script>

<style>
.el-dialog.is-fullscreen {
  background: hsl(225, 20%, 96%);
}
</style>
