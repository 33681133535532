<template>
  <div>
    <p class="bg-blueGray-100 rounded h-full py-2 px-3 relative">
      <span v-if="item.datetime">
        {{ formatTime(item.datetime) }}
      </span>
      <span v-else>
        {{ $t("N/A") }}
      </span>
    </p>
  </div>
</template>

<script>
import formatting from "../../../mixins/formatting";
export default {
  name: "ResultAnswers",
  mixins: [formatting],
  props: {
    item: {
      type: Object,
      require: true
    }
  }
};
</script>
