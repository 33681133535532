<template>
  <div class="bg-blueGray-100 rounded h-full py-2 px-3">
    <div
      class="w-full flex justify-between items-center"
      v-for="(option, i) in item.custom_data.options"
      :key="`${i}-option`"
    >
      <div class="w-11/12">
        <i
          v-if="
            item.custom_data.type == 'checkbox' &&
            !item.multi_value.includes(option.value)
          "
          class="far fa-square"
        />
        <i
          v-if="
            item.custom_data.type == 'checkbox' &&
            item.multi_value.includes(option.value)
          "
          class="fas fa-check-square text-teal-700"
        />
        <i
          v-if="
            item.custom_data.type == 'radio' &&
            item.single_value != option.value
          "
          class="far fa-circle"
        />
        <i
          v-if="
            item.custom_data.type == 'radio' &&
            item.single_value == option.value
          "
          class="fas fa-check-circle text-teal-700"
        />
        <i
          v-if="
            item.custom_data.type == 'option' &&
            item.single_value != option.value
          "
          class="far fa-circle"
        />
        <i
          v-if="
            item.custom_data.type == 'option' &&
            item.single_value == option.value
          "
          class="fas fa-check-circle text-teal-700"
        />
        <i
          v-if="
            item.custom_data.type == 'yesno' &&
            item.single_value != option.value
          "
          class="far fa-circle"
        />
        <i
          v-if="
            item.custom_data.type == 'yesno' &&
            item.single_value == option.value
          "
          class="fas fa-check-circle text-teal-700"
        />
        {{ option.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultOptions",
  props: {
    item: {
      type: Object,
      require: true
    }
  }
};
</script>
