<template>
  <div class="flex">
    <div class="w-1/3 break-normal mr-1">
      <h2 class="font-semibold text-md ml-3 pt-1">
        {{ item.custom_data.question }}
      </h2>
      <h3 class="font-semibold text-sm -mt-1 text-coolGray-400 ml-4">
        {{ item.custom_data.description }}
      </h3>
    </div>
    <div class="w-2/3 break-normal">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultHeading",
  props: {
    item: {
      type: Object,
      require: true
    }
  }
};
</script>
