<template>
  <div class="w-screen h-screen md:flex fixed top-0 left-0">
    <i
      class="fas fa-times fixed right-4 top-3 text-xl cursor-pointer"
      @click="$emit('close')"
    />
    <div class="w-full md:w-2/5 flex items-center px-5 bg-blueGray-100">
      <div>
        <div v-if="checkout && checkout.qr_id">
          <QrCode
            :item="checkout"
            nameKey="subject"
            :borderLess="true"
            :key="checkout.qr_id"
          />
        </div>
        <h1
          class="text-3xl px-8 py-4 font-bold text-blueGray-500 bg-white md:bg-blueGray-100 rounded-t-lg"
        >
          {{ title }}
        </h1>
        <h2
          class="text-xl px-8 -mt-5 font-bold text-blueGray-400 bg-white md:bg-blueGray-100"
          v-if="bike_id"
        >
          <span v-if="bikesByID[bike_id]" class="text-gray-600">
            {{ $t("asset") }}:
          </span>
          <span v-if="bikesByID[bike_id] && bikesByID[bike_id].name">
            {{ bikesByID[bike_id].name }}
          </span>
          /
          <span v-if="bikesByID[bike_id].hub" class="text-gray-600">
            {{ $t("hub") }}:
          </span>
          <span
            v-if="
              bikesByID[bike_id] &&
              bikesByID[bike_id].hub &&
              bikesByID[bike_id].hub.name
            "
          >
            {{ bikesByID[bike_id].hub.name }}
          </span>
          <span v-if="checkListDuration" class="text-gray-600">
            {{ $t("duration") }}:
          </span>
          <span>
            {{ checkListDuration }}
          </span>
        </h2>
      </div>
    </div>
    <div class="h-screen w-full md:w-3/5 overflow-scroll py-20 px-10 bg-white">
      <!-- <div class="overflow-scroll bg-white rounded-b-lg" style="height: 80vh"> -->
      <!-- <div
        class="flex items-left justify-left px-8 pt-3 pb-5"
        v-if="checkout && checkout.qr_id"
      >
        <QrCode
          :item="checkout"
          nameKey="subject"
          :borderLess="true"
          :key="checkout.qr_id"
        />
      </div> -->
      <div
        class="py-1 text-coolGray-700"
        :class="{
          'px-5': item.custom_data.type != 'heading',
          '-mb-2 py-4': item.custom_data.type == 'heading',
          'border-t mt-6': item.custom_data.type == 'heading' && index != 0
        }"
        v-for="(item, index) in items"
        :key="`${item.id}-${index}`"
      >
        <ResultHeading
          v-if="item.custom_data.type == 'heading'"
          :custom_data="item.custom_data"
        />
        <ResultLayout v-if="item.custom_data.type != 'heading'" :item="item">
          <div>
            <ResultCheckoffs
              v-if="item.custom_data.type == 'check'"
              :item="item"
            />
            <ResultAnswers
              :item="item"
              v-if="item.custom_data.type == 'answer'"
            />
            <ResultNumbers
              :item="item"
              v-if="
                item.custom_data.type == 'temp' ||
                item.custom_data.type == 'number'
              "
            />
            <ResultDateTime
              :item="item"
              v-if="item.custom_data.type == 'dateTime'"
            />
            <ResultOptions
              :item="item"
              v-if="
                item.custom_data.type == 'checkbox' ||
                item.custom_data.type == 'option' ||
                item.custom_data.type == 'radio' ||
                item.custom_data.type == 'yesno'
              "
            />
            <ResultMedias
              :medias="item.medias"
              v-if="item.medias && item.medias.length > 0"
            />
          </div>
        </ResultLayout>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import formatting from "../../mixins/formatting";
import ResultHeading from "./result/ResultHeading.vue";
import ResultLayout from "./result/ResultLayout.vue";
import ResultOptions from "./result/ResultOptions.vue";
import ResultAnswers from "./result/ResultAnswers.vue";
import ResultDateTime from "./result/ResultDateTime.vue";
import ResultMedias from "./result/ResultMedias.vue";
import ResultCheckoffs from "./result/ResultCheckoffs.vue";
import ResultNumbers from "./result/ResultNumbers.vue";
import QrCode from "@/components/QrCode.vue";

export default {
  name: "ViewCheckListResult",
  mixins: [formatting],
  components: {
    QrCode,
    ResultHeading,
    ResultLayout,
    ResultOptions,
    ResultAnswers,
    ResultNumbers,
    ResultMedias,
    ResultCheckoffs,
    ResultDateTime
  },
  props: {
    checkout: {
      type: Object,
      default: null
    },
    bike_id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      require: false
    },
    checkListDuration: {
      type: String,
      require: false
    },
    items: {
      type: Array,
      require: true
    }
  },
  computed: {
    ...mapGetters("bikes", ["bikesByID"])
  }
};
</script>
