<template>
  <div
    class="bg-blueGray-100 rounded h-full py-2 px-3 relative"
    :class="{
      'border-rose-700 border': showAlert
    }"
  >
    <p>
      <span v-if="item.single_number_value">
        {{ item.single_number_value }} {{ item.custom_data.unit }}
      </span>
      <span v-else>
        {{ $t("N/A") }}
      </span>
    </p>
    <span
      class="text-rose-700 font-semibold text-xs absolute bottom-0"
      v-if="showAlert"
    >
      {{ $t("value is not in range") }} - {{ $t("max") }}:
      {{ item.custom_data.max }} {{ $t("min") }}:
      {{ item.custom_data.min }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ResultNumbers",
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  computed: {
    showAlert() {
      if (!this.item.custom_data.min && !this.item.custom_data.max) {
        return false;
      }
      return !(
        this.item.single_number_value >= this.item.custom_data.min &&
        this.item.single_number_value <= this.item.custom_data.max
      );
    }
  }
};
</script>
